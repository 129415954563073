import * as S from './style'
import LogoLup from '../../assets/images/LogoLup.png'
import { useNavigate } from "react-router-dom";


function MainLayout({ children, title }) {
    const navigate = useNavigate();

    function removeToken() {
    localStorage.removeItem("token");
    navigate("/");
  }
  return (
    <S.Container>
      <S.Navbar>
        <S.Header
                  onClick={() => {
            navigate("/home");
          }}>
          <img src={LogoLup} alt="logo" />
          <p>Backoffice</p>
        </S.Header>

        <S.Items>
          {/* Conteudo exclusivo de cada projeto */}
                    <p>versão 1.0</p>

        </S.Items>

        <S.GroupItems>
          <S.MenuItems
            onClick={() => {
              navigate("/home");
            }}
          >
            <p>1. Home</p>
          </S.MenuItems>
          <S.MenuItems
            onClick={() => {
              navigate("/consumidores");
            }}
          >
            <p>2. Consumidores</p>
          </S.MenuItems>

          <S.MenuItems
            onClick={() => {
              navigate("/push-notification");
            }}
          >
            <p>3. Push Notification</p>
          </S.MenuItems>
        </S.GroupItems>
      </S.Navbar>

      <S.Content>
        <S.ContentHeader>
          <S.Title>
            {title}
          </S.Title>

          <S.Button
            onClick={() => {
              removeToken();
            }}
          >
            Logout
          </S.Button>
        </S.ContentHeader>
        
        {children}
      </S.Content>
    </S.Container>
  )
}

export default MainLayout;